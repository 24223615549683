import {inject, Injectable, signal, WritableSignal} from '@angular/core'
import {Router} from '@angular/router'
import {
  HelperService,
  SingleSignOnService,
  SparbankenUser,
  TokenPayload
} from '@sparbanken-syd/sparbanken-syd-bankid'
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  Observable,
  of,
  switchMap
} from 'rxjs'
import {environment} from '../../environments/environment'

export const selectedCardsStartValue = [false, false, false]
export const ADMIN_ROLE_NAME = 'simpleSavingsGuideAdmin'

/**
 * Other parts of the application wants to know this data.
 * Note that we DO NOT send the access token here as it
 * is only needed by the auth interceptor, and it reads it
 * from the Observable.
 */
export interface ILogInState {
  /**
   * Below is what we use in this application
   */
  name: string
  sId?: string
}

export const bootstrapResolver = () => {
  const service = inject(ConfigService)
  return service.bootstrap().subscribe()
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * Keeps track of selected cards from those suggested in the last step.
   * Usually, all of suggested cards are also selected.
   */
  public isCardSelected$: WritableSignal<boolean[]> = signal(selectedCardsStartValue)

  /**
   * This is for the "admin", you can add additional
   */
  public isAdmin$ = signal<boolean>(false)

  /**
   * True if we are running inside an iframe/frame on /internetbank route.
   */
  public onInternetBankRoute = false

  /**
   * True if we are running on mobile bank context (&context=mobilbanken).
   */
  public onMobileBankContext = false

  /**
   * Indicating whether the current route is an internal route.
   */
  public onInternalRoute = false

  /**
   * Indicating whether the current route is a Spara route.
   */
  public onSparaRoute = false

  /**
   * The access token, primarily needed for the auth interceptor
   */
  public accessToken$: Observable<string | null>

  /**
   * The user access token if logged in from internetbanken (NeOS)
   */
  public userAccessToken$ = signal<string | null>(null)

  /**
   * Listen to this when you want to know if the login state has changed.
   */
  public logInState$: Observable<ILogInState | null>

  /**
   * Private behaviour subject for observable {@link accessToken$}
   * @private
   */
  private readonly pAccessToken$ = new BehaviorSubject<string | null>(null)
  /**
   * Private behaviour subject for observable {@link logInState$}
   * @private
   */
  private readonly pLogInState$ = new BehaviorSubject<ILogInState | null>(null)
  private readonly ssoService = inject(SingleSignOnService)
  private readonly helperService = inject(HelperService)
  private readonly router = inject(Router)

  constructor() {
    this.accessToken$ = this.pAccessToken$.asObservable()
    this.logInState$ = this.pLogInState$.asObservable()
  }

  /**
   * This cannot be called from APP_INITIALIZER since it
   * will trash the routing
   */
  public bootstrap(): Observable<boolean> {
    return this.sso()
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }

  /**s
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing. Must be anonymous since we
   * call it from merge
   */
  public sso = (): Observable<string> | never => {
    return this.ssoService.getToken(environment.commonServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          // We need to reset when the SSO service logs us out, but there's no need to call logout again
          this.reset()
          return EMPTY
        })
      )
  }

  /**
   * Called whenever we have token, a token can come from two valid sources
   *
   * 1. From the SSO service
   * 2. From BankID login.
   *
   * We do not care, and we validate and set whatever we get.
   */
  public setToken(token: string | null): Observable<boolean> {
    const payload: TokenPayload | null = HelperService.GetTokenPayload(token)
    if (payload) {
      this.pAccessToken$.next(token)
      this.setUserData(payload.roles)
    }
    return of(true)
  }

  /**
   * Logout and go back to login-screen, removing all admin values and SSO.
   */
  public logout(): void {
    // Blindly just log out from SSO, ignore any errors
    this.ssoService.deleteToken(environment.commonServiceUrl).subscribe()
    this.reset()
  }

  /**
   * Reset all admin values, including SSO
   */
  public reset(): void {


    // This can potentially be a long list of resets...
    this.pAccessToken$.next(null)
    this.isAdmin$.set(false)
    this.pLogInState$.next(null)

    // Go to log-in
    this.router.navigate(['/']).then()
  }

  /**
   * In many cases we also want to fetch the user info we do this
   * totally asynchronous and happily accept that the access token
   * is set properly etc.
   *
   * In Legacy applications we have used this to trigger a reset, which is
   * bad.
   */
  private setUserData(roles: string[]): void {
    // Set flag signals as soon as we can. Rest of user info will arrive later
    this.isAdmin$.set(roles.includes(ADMIN_ROLE_NAME))
    if (this.isAdmin$()) {
      this.router.navigate(['admin']).then()
    }

    this.helperService.getCurrentUser(`${environment.commonServiceUrl}`)
      .subscribe({
        next: (user: SparbankenUser) => {
          // Emit login data to subscribers
          this.pLogInState$.next({
            name: user.name,
            sId: user.sId
          })
        }
      })
  }
}
